import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import i18n from '../plugins/i18n'
import login from './login'
import { EMPTY_CONTENT_OBJECT, EMPTY_SEGMENT_OBJECT, randomPastelHex } from '@/utils/helper';
import { parseRotationValue, cleanRulesAssets } from "../utils/helper";

Vue.use(VueAxios, axios)

var headers = {
    "Content-Type": "multipart/form-data"
};

export default {
    async deleteContent({commit}, id) {
        let response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeContentById?id=${id}`
        ).catch( error => {
            console.log(error);
        });
    },
    async deleteContents({commit}, ids) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeContentByIdInList`, ids)
        .catch( error => {
            console.log(error);
        });
    },
    async deleteWheelAssets({commit}, wheelIds) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeAssociatedWheelAssets`, wheelIds)
        .catch( error => {
            console.log(error);
        });
    },
    async updateGameName({commit}, gameName) {
        commit("setGameName", gameName);
    },
    async updateGameDescription({commit}, gameDescription){
        commit("setGameDescription", gameDescription);
    },
    async updateIdleAnimation({commit}, idleAnimation) {
        commit("setIdleAnimation", idleAnimation);
    },
    async updateIdleAnimationTime({commit}, idleAnimationTime) {
        commit("setIdleAnimationTime", idleAnimationTime);
    },
    async updateIdleAnimationDuration({commit}, idleAnimationDuration) {
        commit("setIdleAnimationDuration", idleAnimationDuration);
    },
    async updateStartupAnimation({commit}, animation) {
        commit("setStartupAnimation", animation);
    },
    async updateStartupAnimationTime({commit}, animationTime) {
        commit("setStartupAnimationTime", animationTime);
    },
    async updateStartupAnimationDuration({commit}, animationDuration) {
        commit("setStartupAnimationDuration", animationDuration);
    },
    async updateStartupAnimationLoop({commit}, flag) {
        commit("setStartupAnimationLoop", flag);
    },
    async updateIdleAnimationOptions({commit}, options){
        commit("setIdleAnimationOptions", options)
    },
    async updateContentOptions({commit}, options){
        commit("setContentOptions", options)
    },
    async updateSelectedTier({commit}, selectedTier){
        commit("setSelectedTier", selectedTier)
    },
    async updateTierOptions({commit}, tierOptions){
        commit("setTierOptions", tierOptions)
    },
    async updateSegmentOptions({commit}, segmentImages){
        commit("setSegmentOptions", segmentImages)
    },
    async updateWinningSegmentAnimations({commit}, winningSegmentAnimationOptions){
        commit("setWinningAnimationsOptions", winningSegmentAnimationOptions)
    },
    async updateDropdownsLoaded({commit}, flag) {
        commit("setDropdownsLoaded", flag);
    },
    async updateNumberOfTiers({commit, state}, noOfTiers){
        var tiers = [];

        if (state.selectedGame.tiers.length + 1 >= noOfTiers) {
            for (let i = 0; i < noOfTiers - 1; i++) {
                tiers.push(state.selectedGame.tiers[i]);
            }
        } else {
            // Tier 0 is basewheel which already has the properties added so we start count from 1 
            for (let i = 0; i < state.selectedGame.tiers.length; i++) {
                tiers.push(state.selectedGame.tiers[i]);
            }
            for (let i = state.selectedGame.tiers.length; i < noOfTiers -1 ; i++) {
                tiers.push({
                    name: state.selectedGame.name + "_tier_" + Number(i+2),
                    nextRoundWheel: noOfTiers > i + 2 ? state.selectedGame.name + "_tier_" + Number(i+3) : null, 
                    baseWheel: state.selectedGame.name,
                    animationSize: "100",
                    idle: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        delay: "50",
                        duration: "20" 
                    },
                    animation: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        winningAnimationDuration: "5"
                    },
                    background: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50",
                        rotation: "Counter"
                    },
                    backgroundOverlay: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50",
                        rotation: "None"
                    },
                    wheelOverlay: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50",
                        rotation: "None"
                    },
                    foreground: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50",
                        rotation: "Counter"
                    },
                    no_of_segments: 0,
                    segment_definitions: [
                        {
                            id: 0,
                            name: "Segment 1",
                            bonusSegment: false,
                            image: {
                                id: 0,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 0,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex()
                        },
                        {
                            id: 1,
                            name: "Segment 2",
                            bonusSegment: false,
                            image: {
                                id: 1,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 1,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex()
                        },
                        {
                            id: 2,
                            name: "Segment 3",
                            bonusSegment: false,
                            image: {
                                id: 2,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 2,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex(),
                        }
                    ],
                    segments: []
                });
            }
        }

        state.selectedGame.nextRoundWheel = tiers[0] ? tiers[0].name : null;

        for (let i = 0; i < tiers.length; i++) {
            tiers[i].nextRoundWheel = tiers[i+1] ? tiers[i+1].name : null;
            tiers[i].baseWheel = state.selectedGame.name;
        }

        commit("setTiers", tiers);
        commit("resetBaseGameBonusSegments");
    },
    async updateTierInfo({commit}, payload){
        commit("setTierInfo", payload);
    },
    async updateSegmentChoice({commit}, payload){
        // TODO - CLEAR ALL Segment Defintions as well as centre conten, centre content size and ainning animation size for that tier
        commit("clearSegmentOptions")

        var segments = [];

        for (let i = 0; i < payload.noOfSegments; i++) {
            segments.push(Object.assign({}, EMPTY_SEGMENT_OBJECT));
        }
        let idCount = 0;
        for (let i = 0; i < segments.length; i++) {
            segments[i].id = idCount;
            idCount++;
        }
        
        commit("setSegmentsForTier", {segments: segments, tier: payload.tierIndex});
        commit("setSegmentChoice", {noOfSegments: payload.noOfSegments, tier: payload.tierIndex});
    },
    async updateSegmentImage({commit}, segmentImage) {
        commit("setSegmentImage", segmentImage);
    },
    async updateSegmentAnimation({commit}, segmentAnimation) {
        commit("setSegmentAnimation", segmentAnimation);
    },
    async updateForegroundContent({commit}, foregroundContent) {
        commit("setForegroundContent", foregroundContent);
    },
    async updateForegroundContentSize({commit}, foregroundContentSize) {
        commit("setForegroundContentSize", foregroundContentSize);
    },
    async updateForegroundRotation({commit}, rotation) {
        commit("setForegroundRotation", rotation);
    },
    async updateBackgroundContent({commit}, backgroundContent) {
        commit("setBackgroundContent", backgroundContent);
    },
    async updateBackgroundContentSize({commit}, backgroundContentSize) {
        commit("setBackgroundContentSize", backgroundContentSize);
    },
    async updateBackgroundRotation({commit}, rotation) {
        commit("setBackgroundRotation", rotation);
    },
    async updateWheelOverlayContent({commit}, wheelOverlayContent) {
        commit("setWheelOverlayContent", wheelOverlayContent);
    },
    async updateWheelOverlayContentSize({commit}, wheelOverlayContentSize) {
        commit("setWheelOverlayContentSize", wheelOverlayContentSize);
    },
    async updateWheelOverlayRotation({commit}, rotation) {
        commit("setWheelOverlayRotation", rotation);
    },
    async updateBackgroundOverlayContent({commit}, backgroundOverlayContent) {
        commit("setBackgroundOverlayContent", backgroundOverlayContent);
    },
    async updateBackgroundOverlayContentSize({commit}, backgroundOverlayContentSize) {
        commit("setBackgroundOverlayContentSize", backgroundOverlayContentSize);
    },
    async updateBackgroundOverlayRotation({commit}, rotation) {
        commit("setBackgroundOverlayRotation", rotation);
    },
    async updateWinningAnimationSize({commit}, winningAnimationSize) {
        commit("setWinningAnimationSize", winningAnimationSize);
    },
    async updateWinningAnimationDuration({commit}, winningAnimationDuration) {
        commit("setWinningAnimationDuration", winningAnimationDuration);
    },
    async updateWinningAnimationDelay({commit}, winningAnimationDelay) {
        commit("setWinningAnimationDelay", winningAnimationDelay);
    },
    async updateContentSrc({commit}, src) {
        commit("setContentSrc", src);
    },
    async updateBackgroundSrc({commit}, src) {
        commit("setBackgroundSrc", src);
    },
    async updateWheelOverlaySrc({commit}, src) {
        commit("setWheelOverlaySrc", src);
    },
    async updateBackgroundOverlaySrc({commit}, src) {
        commit("setBackgroundOverlaySrc", src);
    },
    async updateIdleAnimationSrc({commit}, src) {
        commit("setIdleAnimationSrc", src);
    },
    async updateStartupAnimationSrc({commit}, src) {
        commit("setStartupAnimationSrc", src);
    },
    async updateSelectedGameRule({commit}, selectedGameRule) {
        commit("setSelectedGameRule", selectedGameRule);
    },
    async updateSelectedGameSubRule({commit}, selectedGameSubRule) {
        commit("setSelectedGameSubRule", selectedGameSubRule);
    },
    async updateSelectedGameReplacementGroupSegment({commit}, payload) {
        commit("setSelectedGameReplacementGroupSegment", payload);
    },
    async updateSelectedGame({commit}, selectedGame) {
        commit("setSelectedGame", selectedGame);
    },
    async addNewGameAction({commit}, game) {
        commit("addNewGame", game);
    },
    async clearWheels({commit}) {
        commit("clearWheels");
    },
    async addNewTempGameAction({commit}, game) {
        commit("addNewTempGame", game);
    },
    async addNewAsset({commit}, asset) {
        commit("addNewAsset", asset);
    },
    async formatAssetList({commit}) {
        commit("formatAssetList");
    },
    async formatSharedAssetList({commit}) {
        commit("formatSharedAssetList");
    },
    async uploadContent({commit, state}, payload) {
        commit('setUploadContentError', null);
        headers.tenantId = login.state.tenant;
        let data = payload.file.type.includes('image') ? payload.base64 : null;

        let formData = new FormData();
        formData.append("data", data);
        formData.append("type", payload.type); 
        formData.append("prefix", payload.prefix);
        formData.append("mimeType", payload.file.type);
        formData.append("fileName", payload.file.name);
        formData.append("displayName", payload.displayName);
        formData.append("tenantId", login.state.tenant);
        formData.append("groupId", state.selectedGame.wheelGameId); 
        formData.append("file", payload.file);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers }
        ).then(response => {
            let src = payload.file.type.includes('image') ? payload.base64 : URL.createObjectURL(payload.file);
            if (payload.commitMethod) {
                commit(payload.commitMethod, {
                    prefix: payload.prefix,
                    file: payload.file,
                    displayName: payload.file.name,
                    contentId: response.data.id,
                    index: payload.index,
                    src: src,
                    mimeType: payload.file.type
                });
            }
        }).catch( error => {
            console.log(error);
            commit('setUploadContentError', error.message ? error.message : error.code);
        });
    },
    async getContentByPrefixAndTenant({ dispatch }, payload){
        let result = null
        let indexOfForwardSlash = payload.prefix.indexOf('/');
        let prefixWithoutTenant = indexOfForwardSlash !== -1 ? payload.prefix.substring(indexOfForwardSlash + 1) : payload.prefix;
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listByPrefixAndTenantIds?prefix=${prefixWithoutTenant}`,  [payload.tenantId, "shared"])
        .catch( error => {
            console.log(error);
        });
        result = response.data;
        dispatch(payload.update, result);
    },
    async getAllWheels({ dispatch }, payload){
        let wheels = null
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listContentByTenantsAndContentTypes`, {'tenantIds': [payload.tenantId, "shared"], 'contentTypes': ["complete_game", "draft_game"]})
        .catch( error => {
            console.log(error);
        });
        if (!response) return; 
        wheels = response.data;
        for (let i in wheels){
            if (wheels[i].fileName == "null.json") continue;
            if (wheels[i].type != "complete_game" && wheels[i].type != "draft_game") continue;

            let dateObj = new Date(wheels[i].dateCreated);
            let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
            wheels[i].dateCreated = dateCreated;
            wheels[i].noOfTiers = Number(wheels[i].prefix.split('_tier')[0].split('/')[1]);
            wheels[i].noOfSegments = Number(wheels[i].prefix.split('_segment')[0].split('/')[2]);
            wheels[i].noOfSegments = isNaN(wheels[i].noOfSegments) ? '-' : wheels[i].noOfSegments;
            wheels[i].status = wheels[i].type == "complete_game" ? "Complete" : "Draft";
            wheels[i].name = wheels[i].displayName;
            wheels[i].presignedUrl = wheels[i].presignedUrl;
            dispatch(payload.update, wheels[i]);
        }
    },
    async getAssets({commit}){
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listAssetsByTenants`,  [login.state.tenant, "shared"])
        .catch( error => {
            console.log(error);
        });
        commit("formatAssetList", response.data);
        
    },
    async getImageContentByIds({commit, state}) {
        let contentIds = [];
        for (let i = 0; i < state.selectedGame.segment_definitions.length; i++) {
            if (state.selectedGame.segment_definitions[i].image.contentId && state.selectedGame.segment_definitions[i].image.src == null
                 && state.selectedGame.segment_definitions[i].image.mimeType.includes("image")) {
                contentIds.push(state.selectedGame.segment_definitions[i].image.contentId);
            }
        }
        for (let i = 0; i < state.selectedGame.segments.length; i++) {
            if (state.selectedGame.segments[i].image.contentId && state.selectedGame.segments[i].image.src == null) {
                contentIds.push(state.selectedGame.segments[i].image.contentId);
            }
        }
        if (state.selectedGame.foreground.asset.contentId && state.selectedGame.foreground.asset.src == null && state.selectedGame.foreground.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.foreground.asset.contentId);
        }
        if (state.selectedGame.background.asset.contentId && state.selectedGame.background.asset.src == null && state.selectedGame.background.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.background.asset.contentId);
        }
        if (state.selectedGame.backgroundOverlay.asset.contentId && state.selectedGame.backgroundOverlay.asset.src == null && state.selectedGame.backgroundOverlay.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.backgroundOverlay.asset.contentId);
        }
        if (state.selectedGame.wheelOverlay.asset.contentId && state.selectedGame.wheelOverlay.asset.src == null && state.selectedGame.wheelOverlay.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.wheelOverlay.asset.contentId);
        }

        if (state.selectedGame.tiers.length > 0) {
            for (let i = 0; i < state.selectedGame.tiers.length; i++) {
                for (let s = 0; s < state.selectedGame.tiers[i].segment_definitions.length; s++) {
                    if (state.selectedGame.tiers[i].segment_definitions[s].image.contentId && state.selectedGame.tiers[i].segment_definitions[s].image.src == null
                        && state.selectedGame.tiers[i].segment_definitions[s].image.mimeType.includes("image")) {
                        contentIds.push(state.selectedGame.tiers[i].segment_definitions[s].image.contentId);
                    }
                }
                for (let s = 0; s < state.selectedGame.tiers[i].segments.length; s++) {
                    if (state.selectedGame.tiers[i].segments[s].image.contentId && state.selectedGame.tiers[i].segments[s].image.src == null) {
                        contentIds.push(state.selectedGame.tiers[i].segments[s].image.contentId);
                    }
                }
                if (state.selectedGame.tiers[i].foreground.asset.contentId && state.selectedGame.tiers[i].foreground.asset.src == null && state.selectedGame.tiers[i].foreground.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].foreground.asset.contentId);
                }
                if (state.selectedGame.tiers[i].background.asset.contentId && state.selectedGame.tiers[i].background.asset.src == null && state.selectedGame.tiers[i].background.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].background.asset.contentId);
                }
                if (state.selectedGame.tiers[i].backgroundOverlay.asset.contentId && state.selectedGame.tiers[i].backgroundOverlay.asset.src == null && state.selectedGame.tiers[i].backgroundOverlay.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].backgroundOverlay.asset.contentId);
                }
                if (state.selectedGame.tiers[i].wheelOverlay.asset.contentId && state.selectedGame.tiers[i].wheelOverlay.asset.src == null && state.selectedGame.tiers[i].wheelOverlay.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].wheelOverlay.asset.contentId);
                }
            }
        }

        if (contentIds != null && contentIds.length > 0) {
            return axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentByIds`, contentIds);
        }
    },
    async getVideoContentById({_context }, id) {
        return axios({
            method: 'get',
            url: `${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getVideoContentFileById?id=${id}`,
            responseType: 'blob', // Set responseType to 'blob' to handle binary data
          })
    },
    async getRulesImageContentByIds({commit, state}) {
        let contentIds = [];

        if (state.selectedGame.rules && state.selectedGame.rules.replacementSegment && state.selectedGame.rules.replacementSegment.image.id && state.selectedGame.rules.replacementSegment.image.src == null
            && state.selectedGame.rules.replacementSegment.image.mimeType.includes("image")) {
           contentIds.push(state.selectedGame.rules.replacementSegment.image.id);
        }
        if (state.selectedGame.rules && state.selectedGame.rules.replacementGroup) {
            for (let i = 0; i < state.selectedGame.rules.replacementGroup.length; i++) {
                if (state.selectedGame.rules.replacementGroup[i].image.id && state.selectedGame.rules.replacementGroup[i].image.src == null
                     && state.selectedGame.rules.replacementGroup[i].image.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.rules.replacementGroup[i].image.id);
                }
            }
        }
        if (state.selectedGame.rules && state.selectedGame.rules.groups) {
            for (let group in state.selectedGame.rules.groups) {
                let groupObject = state.selectedGame.rules.groups[group];
                for (let i = 0; i < groupObject.length; i++) {
                    if (groupObject[i].image.id && groupObject[i].image.src == null
                         && groupObject[i].image.mimeType.includes("image")) {
                        contentIds.push(groupObject[i].image.id);
                    }
                }
            }
        }

        if (contentIds != null && contentIds.length > 0) {
            return axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentByIds`, contentIds);
        }
    },
    async updateImageContentSrc({ commit }, data){
        commit("setImageContentSrc", data);
        
    },
    async fillNullSegments({ commit }){
        commit("fillNullSegments");
    },
    async updateGameStage({ commit }, stage) {
        commit("setStage", stage);
    },
    async addNewSegmentDefinition({commit}, payload){
        commit("addSegmentDefinition", payload);
    },
    async updateSegmentDefinitionName({commit}, payload) {
        commit("setSegmentDefinitionName", payload);
    },
    async updateSegmentDefinitionGroup({commit}, payload) {
        commit("setSegmentDefinitionGroup", payload);
    },
    async updateSegmentDefinitionBonus({commit}, payload) {
        commit("setSegmentDefinitionBonus", payload);
    },
    async deleteSegmentDefinition({commit}, payload) {
        commit("removeSegmentDefinition", payload);
    },
    async updateOneSegment({commit}, segmentInfo) {
        commit("setGameSegment", segmentInfo);
    },
    async updateWheelGamePresignedUrl({commit}, zipFileContentDto) {
        if (zipFileContentDto == null) return;
        const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentByGroupIdAndDisplayNameAndContentType?groupId=${zipFileContentDto.groupId}&displayName=${zipFileContentDto.displayName}&contentType=complete_game`)
        .catch( error => {
            console.log(error);
        });
        if (!response ||  !response.data) {
            //error could not set game zip URL
            return;
        }
        let completeGameDTO = response.data;
        completeGameDTO.presignedUrl = zipFileContentDto.presignedUrl;
        completeGameDTO.urlCreatedDate = zipFileContentDto.urlCreatedDate;
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/updateContentEntity`, completeGameDTO)
        commit("setWheelGamePresignedUrl", zipFileContentDto.presignedUrl);
    },
    async generateGameJSON({commit, state}){
        var gameJSON = [];

        //format basewheel first
        var baseWheelSegments = [];
        state.selectedGame.segments.forEach(segment => {
            baseWheelSegments.push({
                id: segment.id,
                value: segment.value,
                group: segment.group,
                bonusSegment: segment.bonusSegment || false,
                image: segment.image && segment.image.contentId ? { contentId: segment.image.contentId, name: segment.image.displayName} : null,
                animation: segment.animation && segment.animation.contentId ? { contentId: segment.animation.contentId, name: segment.animation.displayName}  : null,
            });
        });
        
        var baseWheelInfo = {
            name: state.selectedGame.name,
            nextRoundWheel: state.selectedGame.tiers && state.selectedGame.tiers[0] ? 'tier_games/' + state.selectedGame.tiers[0].name : null,
            drawMode: "code",
            animationSize: state.selectedGame.animationSize,
            gameRule: state.selectedGame.gameRule,
            rules: cleanRulesAssets(Object.assign({}, state.selectedGame.rules)),
            background : {
                asset: state.selectedGame.background && state.selectedGame.background.asset && state.selectedGame.background.asset.displayName ? { contentId: state.selectedGame.background.asset.contentId, name: state.selectedGame.background.asset.displayName} : null,
                assetSize: state.selectedGame.background ? state.selectedGame.background.assetSize : null,
                rotation: state.selectedGame.background ? parseRotationValue(state.selectedGame.background.rotation) : null
            },
            backgroundOverlay : {
                asset: state.selectedGame.backgroundOverlay && state.selectedGame.backgroundOverlay.asset && state.selectedGame.backgroundOverlay.asset.displayName ? { contentId: state.selectedGame.backgroundOverlay.asset.contentId, name: state.selectedGame.backgroundOverlay.asset.displayName} : null,
                assetSize: state.selectedGame.backgroundOverlay ? state.selectedGame.backgroundOverlay.assetSize : null,
                rotation: state.selectedGame.backgroundOverlay ? parseRotationValue(state.selectedGame.backgroundOverlay.rotation) : null
            },
            wheelOverlay : {
                asset: state.selectedGame.wheelOverlay && state.selectedGame.wheelOverlay.asset && state.selectedGame.wheelOverlay.asset.displayName ? { contentId: state.selectedGame.wheelOverlay.asset.contentId, name: state.selectedGame.wheelOverlay.asset.displayName}: null,
                assetSize: state.selectedGame.wheelOverlay ? state.selectedGame.wheelOverlay.assetSize : null,
                rotation: state.selectedGame.wheelOverlay ? parseRotationValue(state.selectedGame.wheelOverlay.rotation) : null
            },
            foreground: {
                asset: state.selectedGame.foreground && state.selectedGame.foreground.asset && state.selectedGame.foreground.asset.displayName ? { contentId: state.selectedGame.foreground.asset.contentId, name: state.selectedGame.foreground.asset.displayName} : null,
                assetSize: state.selectedGame.foreground ? state.selectedGame.foreground.assetSize : null,
                rotation: state.selectedGame.foreground ? parseRotationValue(state.selectedGame.foreground.rotation) : null
            },
            idle: {
                asset: state.selectedGame.idle && state.selectedGame.idle.asset && state.selectedGame.idle.asset.displayName ? { contentId: state.selectedGame.idle.asset.contentId, name: state.selectedGame.idle.asset.displayName} : null,
                delay: state.selectedGame.idle ? state.selectedGame.idle.delay : null,
                duration: state.selectedGame.idle ? state.selectedGame.idle.duration : null
            },
            animation: {
                winningAnimationDelay: state.selectedGame.animation ? state.selectedGame.animation.winningAnimationDelay : null,
                asset: state.selectedGame.animation && state.selectedGame.animation.asset && state.selectedGame.animation.asset.displayName ? state.selectedGame.animation.asset.displayName: null,
                winningAnimationDuration: state.selectedGame.animation && state.selectedGame.animation.winningAnimationDuration ? state.selectedGame.animation.winningAnimationDuration: null,
                startupAsset: state.selectedGame.animation && state.selectedGame.animation.startupAsset ? state.selectedGame.animation.startupAsset: null,
                startupAnimationDuration: state.selectedGame.animation && state.selectedGame.animation.startupAnimationDuration ? state.selectedGame.animation.startupAnimationDuration: null,
                startupAnimationDelay: state.selectedGame.animation && state.selectedGame.animation.startupAnimationDelay ? state.selectedGame.animation.startupAnimationDelay: null,
                startupAnimationLoop: state.selectedGame.animation && state.selectedGame.animation.startupAnimationLoop ? state.selectedGame.animation.startupAnimationLoop: null
            },
            segments: baseWheelSegments
        }

        // Add Formatted Base Wheel to the game
        gameJSON.push(baseWheelInfo);

        // iterate over tiers and format
        state.selectedGame.tiers.forEach(tier => {
            
            var tierSegments = [];
            tier.segments.forEach(segment => {
                tierSegments.push({
                    id: segment.id,
                    value: segment.value,
                    group: segment.group,
                    bonusSegment: segment.bonusSegment || false,
                    image: segment.image && segment.image.contentId ? { contentId: segment.image.contentId, name: segment.image.displayName} : null,
                    animation: segment.animation && segment.animation.contentId ? { contentId: segment.animation.contentId, name: segment.animation.displayName}  : null,
                });
            });

            var tierInfo = {
                name: tier.name,
                baseWheel: tier.baseWheel,
                drawMode: "code",
                animationSize: tier.animationSize,
                background : {
                    asset: tier.background && tier.background.asset && tier.background.asset.displayName ? { contentId: tier.background.asset.contentId, name: tier.background.asset.displayName} : null,
                    assetSize: tier.background ? tier.background.assetSize : null,
                    rotation: tier.background ? parseRotationValue(tier.background.rotation) : null
                },
                backgroundOverlay : {
                    asset: tier.backgroundOverlay && tier.backgroundOverlay.asset && tier.backgroundOverlay.asset.displayName ? { contentId: tier.backgroundOverlay.asset.contentId, name: tier.backgroundOverlay.asset.displayName } : null,
                    assetSize: tier.backgroundOverlay ? tier.backgroundOverlay.assetSize : null,
                    rotation: tier.backgroundOverlay ? parseRotationValue(tier.backgroundOverlay.rotation) : null
                },
                wheelOverlay : {
                    asset: tier.wheelOverlay && tier.wheelOverlay.asset && tier.wheelOverlay.asset.displayName ? { contentId: tier.wheelOverlay.asset.contentId, name: tier.wheelOverlay.asset.displayName } : null,
                    assetSize: tier.wheelOverlay ? tier.wheelOverlay.assetSize : null,
                    rotation: tier.wheelOverlay ? parseRotationValue(tier.wheelOverlay.rotation) : null
                },
                foreground: {
                    asset: tier.foreground && tier.foreground.asset && tier.foreground.asset.displayName ? { contentId: tier.foreground.asset.contentId, name: tier.foreground.asset.displayName } : null,
                    assetSize: tier.foreground ? tier.foreground.assetSize : null,
                    rotation: tier.foreground ? parseRotationValue(tier.foreground.rotation) : null
                },
                idle: {
                    asset: tier.idle && tier.idle.asset && tier.idle.asset.displayName ? { contentId: tier.idle.asset.contentId, name: tier.idle.asset.displayName } : null,
                    delay: tier.idle ? tier.idle.delay : null,
                    duration: tier.idle ? tier.duration : null
                },
                animation: {
                    winningAnimationDelay: tier.animation ? tier.animation.winningAnimationDelay : null,
                    asset: tier.animation && tier.animation.asset && tier.animation.asset.displayName ? state.selectedGame.animation.asset.displayName: null,
                    winningAnimationDuration: tier.animation && tier.animation.winningAnimationDuration ? tier.animation.winningAnimationDuration: null,
                },
                segments: tierSegments
            }

            if (tier.nextRoundWheel != null) {
                tierInfo.nextRoundWheel = 'tier_games/' + tier.nextRoundWheel;
            }
            //Add Formatted Tier to the game
            gameJSON.push(tierInfo);
        });

        commit("setGameJSON", gameJSON);
    },
    async uploadWheelGame({commit, state}, payload){
        const data = JSON.stringify(state.gameJSON);
        const jsonFile = new Blob([data], { type: 'application/json' });
        headers.tenantId = login.getters.getTenant();
        let noOfTiers = state.selectedGame.tiers == 0 ? 1 : state.selectedGame.tiers.length + 1;

        let formData = new FormData();
        formData.append("data", "data");
        formData.append("type", payload.incompleteGame ? "incomplete_game" : "game"); 
        formData.append("prefix", headers.tenantId + "/" + noOfTiers + "_tier/" + state.selectedGame.no_of_segments + "_segment");
        formData.append("mimeType", "application/json");
        formData.append("fileName", state.selectedGame.name + ".json");
        formData.append("displayName", state.selectedGame.name);
        formData.append("tenantId", login.getters.getTenant());
        formData.append("id", state.selectedGame.wheelGameId); //If game is being edited, send the original gameId instead of the draft/complete game JSON id
        formData.append("file", jsonFile);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers })
            .then(response => {
                commit("setWheelGameId", response.data.id);
            })
            .catch(error => {
                console.log(error);
            });
    },
    async downloadGameZip({commit}, id){
        try {
            const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getGameById?id=${id}`,{ headers: headers, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'game.zip');
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async downloadContent({commit}, asset){
        try {

            if (asset.mimeType.includes('image')) {
                const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentById?id=${asset.id}`,{ headers: headers, responseType: 'blob'});

                const reader = new FileReader();
                reader.onload = function(event) {
                    try {
                        // Parse the JSON data
                        const jsonData = JSON.parse(event.target.result);
                        let blob;
                        if (jsonData.mimeType.includes("image")) {
                            const binaryImageData = atob(jsonData.data);
                            const uint8Array = new Uint8Array(binaryImageData.length);
                            for (let i = 0; i < binaryImageData.length; i++) {
                                uint8Array[i] = binaryImageData.charCodeAt(i);
                            }
                            blob = new Blob([uint8Array], { type: jsonData.mimeType });
                        } else if (jsonData.mimeType.includes("video")) {
                            
                        }
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', asset.fileName);
                        document.body.appendChild(link);
                        link.click();
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                };
                reader.readAsText(new Blob([response.data]));
            } else if (asset.mimeType.includes('video')) {
                const response = await axios({
                    method: 'get',
                    url: `${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getVideoContentFileById?id=${asset.id}`,
                    responseType: 'blob',
                });
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', asset.fileName);
                document.body.appendChild(link);
                link.click();
            }

          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async getGame({commit}, id){
        try {
            const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentById?id=${id}`)
            .catch( error => {
                console.log(error);
            });
            if (!response.data ||  !response.data.data || response.data.data.length < 100 ) return;
            const contentDto = response.data;
            const gameJsonString = atob(contentDto.data);
            try {
                const gameJsonObject = JSON.parse(gameJsonString);
                gameJsonObject.savedGameId = contentDto.id;
                gameJsonObject.type = contentDto.type;
                gameJsonObject.status = contentDto.type == "complete_game" ? "Complete" : "Draft";
                gameJsonObject.presignedUrl = contentDto.presignedUrl;
                let dateObj = new Date(contentDto.dateCreated);
                let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
                gameJsonObject.dateCreated = dateCreated;
                
                for (let i = 0; i < gameJsonObject.segment_definitions.length; i++) {
                    gameJsonObject.segment_definitions[i].name = gameJsonObject.segment_definitions[i].name.replace(/[^A-Za-z0-9\s_\-@#!.'"\$€£¥]/g, '');
                }
                for (let i = 0; i < gameJsonObject.tiers.length; i++) { 
                    for (let j = 0; j < gameJsonObject.tiers[i].segment_definitions.length; j++) {
                        gameJsonObject.tiers[i].segment_definitions[j].name = gameJsonObject.tiers[i].segment_definitions[j].name.replace(/[^A-Za-z0-9\s_\-@#!.'"\$€£¥]/g, '');
                    }
                }
                
                commit("setGame", gameJsonObject);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async getContent({commit}, id){
        try {
            const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentById?id=${id}`)
            .catch( error => {
                console.log(error);
            });
            if (!response.data ||  !response.data.data) return;
            const contentDto = response.data;
            return contentDto;
        } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
        }
    },
    async updateCustomGameRuleContentId({commit}, contentId) {
        commit("setCustomRuleContentId", contentId);
    },
    async cloneGameAction({commit}){
        commit("cloneGame");
    },
    async updateUploadContentError({commit}, payload) {
        commit("setUploadContentError", payload);
    },
    async saveGame({commit, state}, type) {
        //Clear asset sources before saving JSON. Will retrieve the src by contentId when game is loaded back into wizard
        let selectedGameFormatted = JSON.parse(JSON.stringify(state.selectedGame));
        selectedGameFormatted.idle.asset.src = null;
        if (selectedGameFormatted.animation.startupAsset) {
            selectedGameFormatted.animation.startupAsset.src = null;
        }
        selectedGameFormatted.animation.asset.src = null;
        selectedGameFormatted.background.asset.src = null;
        selectedGameFormatted.backgroundOverlay.asset.src = null;
        selectedGameFormatted.wheelOverlay.asset.src = null;
        selectedGameFormatted.foreground.asset.src = null;
        for (let i = 0; i < selectedGameFormatted.segments.length; i++) {
            selectedGameFormatted.segments[i].animation.src = null;
            selectedGameFormatted.segments[i].image.src = null;
        }
        for (let i = 0; i < selectedGameFormatted.segment_definitions.length; i++) {
            selectedGameFormatted.segment_definitions[i].animation.src = null;
            selectedGameFormatted.segment_definitions[i].image.src = null;
        }

        for (let i = 0; i < selectedGameFormatted.tiers.length; i++) {
            selectedGameFormatted.tiers[i].idle.asset.src = null;
            selectedGameFormatted.tiers[i].animation.asset.src = null;
            selectedGameFormatted.tiers[i].background.asset.src = null;
            selectedGameFormatted.tiers[i].backgroundOverlay.asset.src = null;
            selectedGameFormatted.tiers[i].wheelOverlay.asset.src = null;
            selectedGameFormatted.tiers[i].foreground.asset.src = null;
            for (let j = 0; j < selectedGameFormatted.tiers[i].segments.length; j++) {
                selectedGameFormatted.tiers[i].segments[j].animation.src = null;
                selectedGameFormatted.tiers[i].segments[j].image.src = null;
            }
            for (let j = 0; j < selectedGameFormatted.tiers[i].segment_definitions.length; j++) {
                selectedGameFormatted.tiers[i].segment_definitions[j].animation.src = null;
                selectedGameFormatted.tiers[i].segment_definitions[j].image.src = null;
            }
        }        

        const data = JSON.stringify(selectedGameFormatted);
        const jsonFile = new Blob([data], { type: 'application/json' });
        headers.tenantId = login.getters.getTenant();
        let noOfTiers = selectedGameFormatted.tiers == 0 ? 1 : selectedGameFormatted.tiers.length + 1;

        let formData = new FormData();
        formData.append("data", "data");
        formData.append("type", type + "_game"); 
        formData.append("prefix", headers.tenantId + "/" + noOfTiers + "_tier/" + selectedGameFormatted.no_of_segments + "_segment");
        formData.append("mimeType", "application/json");
        formData.append("fileName", state.selectedGame.name + "_" + type + ".json");
        formData.append("displayName", state.selectedGame.name);
        formData.append("tenantId", login.state.tenant);

        let id = state.selectedGame.savedGameId;
        if (state.selectedGame.status == 'Draft' && type == 'complete') { // If completing a draft game, give a null ID so a new ID is generated
            id = null;
        } else if (state.selectedGame.status == 'Complete' && type == 'draft') {  // If saving a Complete game as a draft, make a new draft game in the DB
            id = null;
            state.selectedGame.status = 'Draft';
            state.selectedGame.type = 'draft_game';
        } else if (type == 'draft') {
            state.selectedGame.status = 'Draft';
            state.selectedGame.type = 'draft_game';
        }
        formData.append("id", id); 
        formData.append("groupId", state.selectedGame.wheelGameId); 
        formData.append("file", jsonFile);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers })
            .then(response => {
                commit("setSavedGameId", response.data.id);
                if (type == 'complete') {
                    state.selectedGame.status = 'Complete';
                }
            })
            .catch(error => {
                console.log(error);
                commit('setUploadContentError', error.message ? error.message : error.code);
            });
        
    },
    async clearSegmentOptions({ commit }) {
        commit("clearSegmentOptions");
    },
    async uploadGameZip({commit, state}) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadGameZip?id=${state.selectedGame.wheelGameId}`, )
        .then(response => {
        })
        .catch(error => {
            console.log(error);
        });
    },
    async saveGameRule({commit, state}) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/saveGameRule?id=${state.selectedGame.savedGameId}&gameRule=${state.selectedGame.gameRule}`, )
        .then(response => {
        })
        .catch(error => {
            console.log(error);
        });
    },
    async saveCustomRule({commit, state}) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/saveCustomRule?id=${state.selectedGame.savedGameId}&gameRule=${state.selectedGame.gameRule}`, )
        .then(response => {
        })
        .catch(error => {
            console.log(error);
        });
    },
};
