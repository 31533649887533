<template>
<v-app>
  <template>
    <div
      v-if="bannerMessage && bannerColour"
      class="sticky-banner"
      :style="'background-color: ' + bannerColour + '; color: ' + bannerTextColour + ';'"
    >
      <p>{{ bannerMessage }}</p>
    </div>
    <div v-if="!isLoginPage">
      <NavigationDrawer />
      <AppBar />
      <v-divider class="background"></v-divider>
    </div>
    <v-main>
      <v-container fluid :class="isLoginPage ? 'fill-height pa-0' : ''">
        <router-view></router-view>
      </v-container>
    </v-main>
  </template>
</v-app>
</template>

<script>
import AppBar from '@/components/structural/AppBar';
import NavigationDrawer from '@/components/structural/NavigationDrawer';
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer
  },
  data: () => ({
    bannerColour: null,
    bannerTextColour: null,
    bannerMessage: null
  }),
  mounted() {
    this.loadBannerJsonFile();
  },
  computed: {
    ...mapGetters(["isLoginPage"]),
  },
  methods: {
    async loadBannerJsonFile() {
      try {
        const response = await fetch('/banner.json');
        if (response.ok) {
          let text = await response.text();
          let json = JSON.parse(text);
          this.bannerColour = json.colour;
          this.bannerTextColour = json.textColour;
          this.bannerMessage = json.message;
        } else {
          return;
        }
      } catch (error) {
        console.error("No banner json file found.");
      }
    }
  },
};
</script>
<style>
.sticky-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 34px;
    padding-top: 8px;
    text-align: center;
    font-size: 12px;
    color: black;
    z-index: 1000; /* Ensure it stays on top of other content */
  }
</style>