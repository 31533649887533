function randomPastelHex() {
    let R = Math.floor((Math.random() * 127) + 127);
    let G = Math.floor((Math.random() * 127) + 127);
    let B = Math.floor((Math.random() * 127) + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;      
}

function parseRotationValue(val) {
    if (val == "Double Speed") {
        val = "doubleSpeed";
    } else {
        val = val.toLowerCase();
    }
    return val;
}

function cleanRulesAssets(rules) {
    const rulesCopy = JSON.parse(JSON.stringify(rules));

    if (rulesCopy.replacementSegment) {
        if (rulesCopy.replacementSegment.image && rulesCopy.replacementSegment.image.displayName && rulesCopy.replacementSegment.image.id) {
            rulesCopy.replacementSegment.image = { contentId: rulesCopy.replacementSegment.image.id, name: rulesCopy.replacementSegment.image.displayName};
        }
        if (rulesCopy.replacementSegment.animation) {
            rulesCopy.replacementSegment.animation = { contentId: rulesCopy.replacementSegment.animation.id, name: rulesCopy.replacementSegment.animation.displayName};
        }
        rulesCopy.replacementSegment = cleanSegment(rulesCopy.replacementSegment);
    } else if (rulesCopy.replacementGroup) {
        for (let i = 0; i < rulesCopy.replacementGroup.length; i++) {
            if (rulesCopy.replacementGroup[i].image && rulesCopy.replacementGroup[i].image.displayName && rulesCopy.replacementGroup[i].image.id) {
                rulesCopy.replacementGroup[i].image = { contentId: rulesCopy.replacementGroup[i].image.id, name: rulesCopy.replacementGroup[i].image.displayName};
            }
            if (rulesCopy.replacementGroup[i].animation) {
                rulesCopy.replacementGroup[i].animation = { contentId: rulesCopy.replacementGroup[i].animation.id, name: rulesCopy.replacementGroup[i].animation.displayName};
            }
            rulesCopy.replacementGroup[i] = cleanSegment(rulesCopy.replacementGroup[i]);
        }
    } else if (rulesCopy.groups) {
        for (let group in rulesCopy.groups) {
            if (rulesCopy.groups.hasOwnProperty(group)) {
                for (let i = 0; i < rulesCopy.groups[group].length; i++) {
                    if (rulesCopy.groups[group][i].image && rulesCopy.groups[group][i].image.displayName && (rulesCopy.groups[group][i].image.contentId || rulesCopy.groups[group][i].image.id)) {
                        rulesCopy.groups[group][i].image = { 
                            contentId: rulesCopy.groups[group][i].image.contentId ? rulesCopy.groups[group][i].image.contentId : rulesCopy.groups[group][i].image.id, 
                            name: rulesCopy.groups[group][i].image.displayName
                        };
                    }
                    if (rulesCopy.groups[group][i].animation && rulesCopy.groups[group][i].animation.displayName && (rulesCopy.groups[group][i].animation.contentId || rulesCopy.groups[group][i].animation.id)) {
                        rulesCopy.groups[group][i].animation = { 
                            contentId: rulesCopy.groups[group][i].animation.contentId ? rulesCopy.groups[group][i].animation.contentId : rulesCopy.groups[group][i].animation.id, 
                            name: rulesCopy.groups[group][i].animation.displayName
                        };
                    }
                    rulesCopy.groups[group][i] = cleanSegment(rulesCopy.groups[group][i]);
                }
            }
        }
    }
    return rulesCopy;
}

function cleanSegment(segment) {
    return segment = {
        id: segment.id,
        value: segment.value,
        group: segment.group,
        bonusSegment: segment.bonusSegment || false,
        image: segment.image,
        animation: segment.animation,
        winningAnimationDuration: segment.winningAnimationDuration,
        winningAnimationDelay: segment.winningAnimationDelay,
        winningAnimationSize: segment.winningAnimationSize
    };
}

var STARTING_SEGMENT_DEFINITIONS = [
    {
        id: 0,
        name: null,
        bonusSegment: false,
        image: {
            id: 0,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 0,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex()
    },
    {
        id: 1,
        name: null,
        bonusSegment: false,
        image: {
            id: 1,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 1,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex()
    },
    {
        id: 2,
        name: null,
        bonusSegment: false,
        image: {
            id: 2,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 2,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex(),
    }
];

var EMPTY_CONTENT_OBJECT = {
    contentId: null,
    file: null,
    base64: null,
    name: null,
};

var EMPTY_SEGMENT_OBJECT = {
    id: null,
    text: null,
    value: null,
    bonusSegment: false,
    image: Object.assign({}, EMPTY_CONTENT_OBJECT),
    animation: Object.assign({}, EMPTY_CONTENT_OBJECT),
    animationLength: 10,
    winningAnimationDuration: null,
    fillStyle: randomPastelHex()
};


export { randomPastelHex , STARTING_SEGMENT_DEFINITIONS, EMPTY_CONTENT_OBJECT, EMPTY_SEGMENT_OBJECT, parseRotationValue, cleanRulesAssets };