<template>
  <v-container fluid fill-height class="background fill-height">
    <div
      v-if="bannerMessage && bannerColour"
      class="sticky-banner"
      :style="'background-color: ' + bannerColour + '; color: ' + bannerTextColour + ';'"
    >
      <p>{{ bannerMessage }}</p>
    </div>
    <v-card class="white mx-auto" width="60vw" height="50vh" min-height="480px">
      
      <div class="white">
        <v-img max-width="20vw" class="mx-auto" src="../assets/DigiWheel_Logo_Evolution_Company_RGB_Yellow_Black.svg"/>
      </div>

      <p class="text-h10 pt-2 pb-2 white text-center">{{ $t('login.title') }}</p>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="text-center"
      >
        <v-text-field
          v-model="username"
          :placeholder="$t('login.username')"
          class="mb-n2 rounded-lg mx-8"
          outlined
          density="compact"
          single-line
          data-test="email-input"
        ></v-text-field>
    
        <v-text-field
          v-model="password"
          :placeholder="$t('login.password')"
          class="mb-n2 rounded-lg mx-8"
          outlined
          single-line
          density="compact"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          data-test="password-input"
        ></v-text-field>

        <v-btn
          width="35vw"
          color="black white--text"
          class="rounded-lg mb-3"
          @click="loginClick()"
          data-test="btn-login"
        >
          {{ $t('login.enter') }}
        </v-btn>
        <p style="display: none">{{ $t('login.reset') }}</p>
      </v-form>

    </v-card>
  </v-container>
</template>

  <script>
import { mapActions, mapGetters } from 'vuex';
  export default {
    name: "LogIn",
    data: () => ({
      valid: false,
      username: '',
      password: '',
      showPassword: false,
      bannerColour: null,
      bannerTextColour: null,
      bannerMessage: null
    }),
    mounted() {
      this.setLoginPage(true);
      this.loadBannerJsonFile();
    },
    computed: {
      ...mapGetters([
        "getLoginFailed",
      ]),
      loginFailed() {
        return this.getLoginFailed;
      },
    },
    methods: {
      ...mapActions(["login", "setLoginPage"]),
      loginClick() {
        this.login({'username': this.username.trim(), 'password': this.password.trim() })
          .then(() => {
            if (this.getLoginFailed) {
              this.$toast.error("Incorrect login. Please try again.");
            }  
          })
         
      },
      async loadBannerJsonFile() {
        try {
          const response = await fetch('/banner.json');
          if (response.ok) {
            let text = await response.text();
            let json = JSON.parse(text);
            this.bannerColour = json.colour;
            this.bannerTextColour = json.textColour;
            this.bannerMessage = json.message;
          } else {
            return;
          }
        } catch (error) {
          console.log("No banner json file found.");
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .fadeout {
    animation: fadeout 2s forwards;
  }
  
  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  .sticky-banner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 34px;
    padding-top: 8px;
    text-align: center;
    font-size: 12px;
    color: black;
    z-index: 1000; /* Ensure it stays on top of other content */
  }
  </style>
  